/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useToast } from '@chakra-ui/react'
import { Address } from 'cluster'
import { v4 as uuid } from 'uuid'
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { SignIn } from '../Pages/SignIn'
import { ChatApiRemoteService, TRatingMessageDTO } from '../Services/protocols/service-chat'
import { FavoritesApiRemoteService, TListFavoritesResponse } from '../Services/protocols/service-favorites'
import { SuggestionApiRemoteService, TListSuggestionResponse } from '../Services/protocols/service-suggestion'
import { CreateThreadUseCase } from '../Usecase/Chat/create-thread-usecase'
import api from '../Services/api'
import { SendMessageUseCase } from '../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../Usecase/Chat/rating-message-usecase'
import { ListSuggestionUseCase } from '../Usecase/Chat/list-suggestion-usecase'
import { CreateFavoritesUseCase } from '../Usecase/Chat/create-favorite-usecase'
import { ListFavoritesUseCase } from '../Usecase/Chat/list-favorites-usecase'
import { DeleteFavoritesUseCase } from '../Usecase/Chat/delete-favorite-usecase'
import { ToastCustom } from '../Components/ToastCustom'
import { StorageLocalstorage } from '../Shared/storage/storage-localstorage'
import { CUSTOMER } from '../Config/constants'
import { format } from 'date-fns'
import { text } from 'stream/consumers'
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter'
import { GetMessageUseCase } from '../Usecase/Chat/get-message-usecase'

export interface IMessagesChatProps {
  id: string
  role: 'assistant' | 'user'
  message: string
  chatId?: string
  favoriteId?: string
}

export interface IAssistantProps {
  model: string
  assistant_id: string
  type: string
}

export interface UserProps {
  id: string
  name: string
  email: string
  phone: string
  cpf: string
}

export interface HandleSendMessageProps {
  text?: string
  refresh?: boolean
  showInFront?: boolean
  favoriteId?: string
}

interface ChatProps {
  threadId: string
  handleCreateThread: (version: string) => Promise<void>
  isLoading: boolean
  threadIsLoading: boolean
  messages: IMessagesChatProps[]
  handleSendMessage: (data: HandleSendMessageProps) => Promise<any>
  chatLoading: string
  handleRatingMessage: ({ chatId, rating, reason }: TRatingMessageDTO) => Promise<void>
  attemptToCreateThreadId: number
  suggestions: TListSuggestionResponse[]
  handleListSuggestions: () => Promise<void>
  favorites: TListFavoritesResponse[]
  handleListFavorites: () => Promise<void>
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>
  handleDeleteFavorite: (id: string) => Promise<void>
  errorLimitAttemptThreadId: boolean
  setUserToken: (id: string) => void
  handleClearChat: () => Promise<void>
  start: boolean
  setStart: (state: boolean) => void
  setIsCvc: (state: boolean) => void
  isCvc: boolean
  setVersionAI: (version: number) => void
  canChangeAiVersion: boolean
  versionAI: number
  allowedEmailsToV2: string[]
  setCanChangeAiVersion: (state: boolean) => void
  isNalkEmail: (email: string) => boolean
  handlePreFillMessage: (data: HandleSendMessageProps) => void
  preFillMessage: string
}

interface ISendMessagePayloadProps {
  threadId: string
  message: string
  initialData: boolean | null
  version: number

}

const attemptToCreateThreadIdLimit = 4

const ChatContext = createContext({} as ChatProps)

export const ChatProvider: FC<any> = ({ children }) => {
  const [chatLoading, setChatLoading] = useState<string>('')
  const [threadIsLoading, setThreadIsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<IMessagesChatProps[]>([])
  const [threadId, setThreadId] = useState('')
  const [attemptToCreateThreadId, setAttemptToCreateThreadId] = useState(0)
  const [suggestions, setSuggestions] = useState<TListSuggestionResponse[]>([])
  const [favorites, setFavorites] = useState<TListFavoritesResponse[]>([])
  const [errorLimitAttemptThreadId, setErrorLimitAttemptThreadId] = useState(false)

  const [userToken, setUserToken] = useState('')
  const [start, setStart] = useState(false)
  const [isCvc, setIsCvc] = useState(false)
  const [versionAI, setVersionAI] = useState(2)
  const [canChangeAiVersion, setCanChangeAiVersion] = useState(false)
  const [preFillMessage, setPreFillMessage] = useState('')

  const allowedEmailsToV2 = ['diego@nalk.com.br', 'arinca@nalk.com.br']

  const [pollingId, setPollingId] = useState<null | number | NodeJS.Timer>(null)

  const InitialData = useRef(false)

  // Remotes Api
  const chatApiRemoteService = new ChatApiRemoteService(api)
  const favoriteApiRemoteService = new FavoritesApiRemoteService(api)

  // Usecases
  const createThreadUseCase = new CreateThreadUseCase(chatApiRemoteService)
  const sendMessageUseCase = new SendMessageUseCase(chatApiRemoteService)
  const ratingMessageUseCase = new RatingMessageUseCase(chatApiRemoteService)
  const listSuggestionUseCase = new ListSuggestionUseCase(new SuggestionApiRemoteService(api))
  const listFavoritesUseCase = new ListFavoritesUseCase(favoriteApiRemoteService)
  const createFavoritesUseCase = new CreateFavoritesUseCase(favoriteApiRemoteService)
  const deleteFavoritesUseCase = new DeleteFavoritesUseCase(favoriteApiRemoteService)
  const getMessageUseCase = new GetMessageUseCase(chatApiRemoteService)

  const toast = useToast()
  const didMountRef = useRef(false)

  useEffect(() => {
    const greetingMessage: IMessagesChatProps = {
      id: uuid(),
      role: 'assistant',
      message: 'Como posso ajudar?'
    }
    setMessages([greetingMessage])
  }, [])

  const createPoolling = async ({ chatId, internalMessageId, internalUserMessageId, favoriteId }: { internalUserMessageId: string; chatId: string; internalMessageId: string; favoriteId?: string }): Promise<void> => {
    try {
      const chatResult = await getMessageUseCase.handle(chatId)

      if (chatResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={chatResult.error?.error ?? 'Erro ao buscar a mensagem. Por favor, tente novamente'} />
        })

        return
      }

      const response = chatResult.getValue()

      if (response?.answer) {
        setMessages((prev) => {
          const index = prev.findIndex((i) => i.id === internalMessageId)

          prev[index] = {
            ...prev[index],
            message: String(response.answer),
            chatId: favoriteId || response.id
          }

          const indexUserMessage = prev.findIndex((i) => i.id === internalUserMessageId)

          prev[indexUserMessage] = {
            ...prev[indexUserMessage],
            chatId: favoriteId || response.id,
            favoriteId: favoriteId || undefined
          }
          return prev
        })

        clearInterval(pollingId as number)
        console.log('interval cleaned')
        setPollingId(null)
        setChatLoading('')
      }
    } catch (error) {
      console.log('createPoolling error: ', error)
    }
  }

  useEffect(() => {
    // Limpa o polling quando o componente desmonta ou o polling não for mais necessário
    return () => {
      if (pollingId) {
        clearInterval(pollingId)
        console.log('interval cleaned')
      }
    }
  }, [pollingId])

  const handleSendMessage = async ({ favoriteId, showInFront = true, refresh, text }: HandleSendMessageProps): Promise<void> => {
    if (!text) return
    const internalMessageId = uuid()
    const internalUserMessageId = uuid()

    setChatLoading(internalMessageId)
    try {
      const normalizeMessage = refresh && messages.length > 0 ? String(messages[messages.length - 2].message) : text ?? ''

      const messageUser: IMessagesChatProps = {
        id: internalUserMessageId,
        role: 'user',
        message: capitalizeFirstLetter(normalizeMessage)
      }

      const messageBot: IMessagesChatProps = {
        id: internalMessageId,
        role: 'assistant',
        message: ''
      }

      if (refresh) {
        setMessages(prev => {
          const newChats = [...prev]
          newChats.pop()
          newChats.push(messageBot)
          return newChats
        })
      } else {
        if (showInFront) {
          setMessages((prev) => [...prev, messageUser, messageBot])
        }
      }

      const normalizePayloadMessage = refresh && messages.length > 0 ? String(messages[messages.length - 2].message) : text ?? ''

      const payload: ISendMessagePayloadProps = {
        message: capitalizeFirstLetter(normalizePayloadMessage),
        threadId,
        initialData: messages.length === 1 ? true : null,
        version: versionAI
      }

      const chatResult = await sendMessageUseCase.handle(payload)

      if (chatResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={chatResult.error?.error ?? 'Erro ao enviar a mensagem. Por favor, tente novamente'} />
        })

        return
      }

      const response = chatResult.getValue()

      if (versionAI === 2 && response?.chatId && showInFront) {
        const id = setInterval(() => {
          console.log('chamou o polling')
          void createPoolling({ chatId: response.chatId, internalUserMessageId, favoriteId, internalMessageId })
        }, 5000)

        setPollingId(id) // Armazena o ID do polling no state
      }

      if (response?.chatId && response.message && showInFront) {
        setMessages((prev) => {
          const index = prev.findIndex((i) => i.id === internalMessageId)

          prev[index] = {
            ...prev[index],
            message: response.message,
            chatId: favoriteId || response.chatId
          }

          const indexUserMessage = prev.findIndex((i) => i.id === internalUserMessageId)

          prev[indexUserMessage] = {
            ...prev[indexUserMessage],
            chatId: favoriteId || response.chatId,
            favoriteId: favoriteId || undefined
          }
          return prev
        })
        setChatLoading('')
      }

      // setChatLoading('')
    } catch (error) {
      console.log('erro no back: ', error)
      toast({
        title: 'Erro ao criar a mensagem',
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setPreFillMessage('')
    }
  }

  const handlePreFillMessage = (data: HandleSendMessageProps): void => {
    if (data?.text) {
      setPreFillMessage(data.text)
    }
  }

  const handleRatingMessage = async ({ chatId, rating, reason }: TRatingMessageDTO): Promise<void> => {
    try {
      if (versionAI === 1) return
      await ratingMessageUseCase.handle({ chatId, rating, reason })
    } catch (error) {
      console.log('error handleRatingMessage: ', error)
      toast({
        title: error.response.data.error,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }

  const handleCreateThread = async (version: string): Promise<void> => {
    try {
      setThreadIsLoading(true)
      const responseResult = await createThreadUseCase.handle(version)

      if (responseResult.isFailure) {
        setAttemptToCreateThreadId(prev => prev + 1)

        if (attemptToCreateThreadId + 1 >= attemptToCreateThreadIdLimit) {
          setThreadIsLoading(false)
        }

        // toast({
        //   title: responseResult.error?.message,
        //   position: 'top-right',
        //   isClosable: true,
        //   status: 'error',
        //   variant: 'solid'
        // })

        return
      }

      const response = responseResult.getValue()

      if (response?.threadId) {
        setThreadId(response.threadId)
        setThreadIsLoading(false)
      }
    } catch (error) {
      toast({
        title: error.response.data.error,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }

  const handleListSuggestions = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const responseResult = await listSuggestionUseCase.handle()

      if (responseResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={responseResult.error?.error ?? 'Erro ao listar as sugestões'} />
        })

        return
      }

      const response = responseResult.getValue()

      if (response?.length) {
        setSuggestions(response)
      }

      setIsLoading(false)
    } catch (error) {
      toast({
        title: error.response.data.error,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }

  const handleListFavorites = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const responseResult = await listFavoritesUseCase.handle()

      if (responseResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description='Erro ao listar os favoritos' />
        })

        return
      }

      const response = responseResult.getValue()

      if (response?.length) {
        setFavorites(response)
      }

      setIsLoading(false)
    } catch (error) {
      toast({
        title: error.response.data.error,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }

  const handleCreateFavorite = async (name: string, messageId: string): Promise<string | undefined> => {
    try {
      setIsLoading(true)
      const responseResult = await createFavoritesUseCase.handle(name)

      if (responseResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={responseResult.error?.error ?? ''} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Pergunta favoritada' description='Você pode acessá-la na aba "Favoritas”' />
      })

      await handleListFavorites()

      const responseData = responseResult.getValue()

      const indexMessage = messages.findIndex(chat => chat.chatId === messageId && chat.role === 'user')

      if (indexMessage > -1) {
        const newMessages = [...messages]
        newMessages[indexMessage].favoriteId = String(responseData)
        setMessages(newMessages)
      }

      return responseData ?? undefined
    } catch (error) {
      console.log('error: ', error)
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Erro' description={error} />
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteFavorite = async (id: string): Promise<void> => {
    try {
      setIsLoading(true)
      const responseResult = await deleteFavoritesUseCase.handle(id)

      if (responseResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={responseResult.error?.error ?? ''} />
        })

        return
      }

      toast({

        position: 'top-right',
        render: () => <ToastCustom type='neutral' title='Desfavoritada' description='Pergunta removida da aba "Favoritas”' />
      })

      const indexMessage = messages.findIndex(chat => chat.favoriteId === id && chat.role === 'user')

      if (indexMessage > -1) {
        const newMessages = [...messages]
        newMessages[indexMessage].favoriteId = undefined
        setMessages(newMessages)
      }

      await handleListFavorites()
    } catch (error) {
      toast({
        title: error.response.data.error,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleClearChat = async (): Promise<void> => {
    setMessages([messages[0]])
    setThreadIsLoading(true)
    InitialData.current = false
    await handleCreateThread(String(versionAI))
  }

  useEffect(() => {
    if (!didMountRef.current && userToken) {
      didMountRef.current = true
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleCreateThread(String(versionAI))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  const isNalkEmail = (email: string): boolean => {
    return email.endsWith('@nalk.com.br')
  }

  useEffect(() => {
    const getToken: { token: string } = StorageLocalstorage.get(`${CUSTOMER}:token`)

    const getCustomer: { customer: string } = StorageLocalstorage.get(`${CUSTOMER}:customer`)

    const getEmail: { email: string } = StorageLocalstorage.get(`${CUSTOMER}:email`)

    console.log('getEmail: ', getEmail)

    if ((getEmail?.email && isNalkEmail(getEmail?.email)) || getCustomer?.customer === 'cvc') {
      setVersionAI(2)
      setCanChangeAiVersion(true)
    }
    // if (getCustomer?.customer === 'cvc') {
    //   setIsCvc(true)
    // }

    if (getToken) {
      setUserToken(getToken.token)
    }
  }, [])

  useEffect(() => {
    if (attemptToCreateThreadId === 0) return

    if (attemptToCreateThreadId === attemptToCreateThreadIdLimit) {
      setErrorLimitAttemptThreadId(true)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async (): Promise<void> => {
      await handleCreateThread(String(versionAI))
    }, 3000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptToCreateThreadId])

  // const handleStartChatWithDate = async (): Promise<void> => {
  //   try {
  //     await handleSendMessage({ text: `A data de hoje é ${format(new Date(), 'dd/MM/yyyy')}`, refresh: false, showInFront: false })
  //     InitialData.current = true
  //     setThreadIsLoading(false)
  //   } catch (error) {
  //     console.log('erro ao enviar a data inicial')
  //   }
  // }

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/space-before-blocks
  //   if (threadId && !InitialData.current){
  //     // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //     handleStartChatWithDate()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [threadId])

  return (
      <ChatContext.Provider
        value={{
          threadId,
          attemptToCreateThreadId,
          chatLoading,
          favorites,
          handleCreateFavorite,
          handleCreateThread,
          handleDeleteFavorite,
          handleListFavorites,
          handleListSuggestions,
          handleRatingMessage,
          handleSendMessage,
          isLoading,
          messages,
          suggestions,
          errorLimitAttemptThreadId,
          threadIsLoading,
          setUserToken,
          handleClearChat,
          start,
          setStart,
          setIsCvc,
          isCvc,
          setVersionAI,
          canChangeAiVersion,
          versionAI,
          allowedEmailsToV2,
          setCanChangeAiVersion,
          isNalkEmail,
          handlePreFillMessage,
          preFillMessage
        }}>
        {children}
      </ChatContext.Provider>
  )
}

export const useChat = (): ChatProps => {
  const context = useContext(ChatContext)

  if (!context) {
    throw new Error('useChat must be used inside an ChatProvider')
  }

  return context
}
