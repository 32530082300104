/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Flex, Radio, RadioGroup, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useRef, useState } from 'react'
import { IAFloat } from '../../Components/IAFloat'
import { CreateThreadUseCase } from '../../Usecase/Chat/create-thread-usecase'
import { SendMessageUseCase } from '../../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../../Usecase/Chat/rating-message-usecase'
import { ListSuggestionUseCase } from '../../Usecase/Chat/list-suggestion-usecase'
import { ListFavoritesUseCase } from '../../Usecase/Chat/list-favorites-usecase'
import { CreateFavoritesUseCase } from '../../Usecase/Chat/create-favorite-usecase'
import { DeleteFavoritesUseCase } from '../../Usecase/Chat/delete-favorite-usecase'
import { MdLogin } from 'react-icons/md'
import { ModalSignOut } from '../../Components/Modals/ModalSignOut'
import { CUSTOMER } from '../../Config/constants'
import { StorageLocalstorage } from '../../Shared/storage/storage-localstorage'
import { CreateMetabaseTokenUseCase } from '../../Usecase/Auth/create-metabase-token-usecase'

import { ToastCustom } from '../../Components/ToastCustom'
import { useChat } from '../../Hooks/useChat'
import { AnyPtrRecord } from 'dns'

interface Props {
  createThreadUseCase: CreateThreadUseCase
  sendMessageUseCase: SendMessageUseCase
  ratingMessageUseCase: RatingMessageUseCase
  listSuggestionUseCase: ListSuggestionUseCase
  listFavoritesUseCase: ListFavoritesUseCase
  createFavoritesUseCase: CreateFavoritesUseCase
  deleteFavoritesUseCase: DeleteFavoritesUseCase
  createMetabaseTokenUseCase: CreateMetabaseTokenUseCase
}

const FullEmbbedMetaBasePage: FC<Props> = ({ createThreadUseCase, ratingMessageUseCase, sendMessageUseCase, listSuggestionUseCase, listFavoritesUseCase, createFavoritesUseCase, deleteFavoritesUseCase, createMetabaseTokenUseCase }) => {
  const [metabaseToken, setMetabaseToken] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const toast = useToast()

  const InitialToken = useRef(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { canChangeAiVersion, versionAI, setVersionAI, handleCreateThread } = useChat()

  const metabaseUrl = 'https://app.nalk.com.br' // URL do Metabase
  const metabaseUrlIframeReturn = 'https://app.nalk.com.br?top_nav=true&new_button=true&search=true'

  const updateMetabaseToken = async (): Promise<void> => {
    try {
      const metabaseToken = await createMetabaseTokenUseCase.handle()

      if (metabaseToken.isFailure) {
        console.log('erro ao gerar token metabase: ', metabaseToken?.error?.error)
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={metabaseToken.error?.error ?? 'Erro ao logar. Por favor, tente novamente'} />
        })

        return
      }

      const response = metabaseToken.getValue()

      if (response?.token) {
        setMetabaseToken(response.token)
        // Enviar o novo token para o iframe
        // if (iframeRef.current) {
        //   iframeRef.current.contentWindow?.postMessage(
        //     { type: 'UPDATE_TOKEN', token: response.token },
        //     metabaseUrl
        //   )
        // }
      }
    } catch (error) {
      // toast({
      //     position: 'top-right',
      //     render: () => <ToastCustom type='fail' title='Erro' description={metabaseToken?.error?.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      //   })
      console.log('error updateMetabaseToken: ', error)
    }
  }

  useEffect(() => {
    if (!InitialToken.current) {
      updateMetabaseToken()
      InitialToken.current = true
    }
  }, [])

  const handleChangeAI = async (e: number): Promise<void> => {
    console.log('handleChangeAI e: ', e)
    setVersionAI(Number(e))

    await handleCreateThread(String(e))
    setVersionAI(e)
  }

  return (
    <Flex
      w="100%"
      height="100vh"
      direction="column"
      align="center"
      justify="center"
    >
        {openModal && <ModalSignOut onClose={() => setOpenModal(false)} /> }

      <Flex position='absolute' right='24px' top='15px'>
        {/* {canChangeAiVersion && <RadioGroup onChange={async (value) => await handleChangeAI(Number(value))} value={versionAI} name="version">
                                <Flex gap="20px" marginRight="20px">
                                  <Radio value={1}>Versão 1.0</Radio>
                                  <Radio value={2}>Versão 2.0</Radio>
                                </Flex>
                              </RadioGroup>
        } */}
        <MdLogin size='24px' onClick={() => setOpenModal(true)} cursor='pointer' />
      </Flex>

      {metabaseToken && (
        <iframe
          ref={iframeRef}
          src={`${metabaseUrl}/auth/sso?jwt=${metabaseToken}&return_to=${encodeURIComponent(metabaseUrlIframeReturn)}`}
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen
          title="Metabase"

          style={{ marginTop: '45px' }}
        ></iframe>
      )}

      <IAFloat createThreadUseCase={createThreadUseCase} ratingMessageUseCase={ratingMessageUseCase} sendMessageUseCase={sendMessageUseCase} listSuggestionUseCase={listSuggestionUseCase} listFavoritesUseCase={listFavoritesUseCase} createFavoritesUseCase={createFavoritesUseCase} deleteFavoritesUseCase={deleteFavoritesUseCase} />
    </Flex>
  )
}

export { FullEmbbedMetaBasePage }
